export default (props, store) => {
  let customEventName = config.tracking.defaultCustomEventName;
  let debug = config.tracking.debug;
  let category = "";
  let pageName = "";
  let presetKey = null;

  let result = {};
  let setCategory = (name) => {
    category = name;
    return result;
  };

  let setPageName = (name) => {
    pageName = name;
    return result;
  };

  let setTrackingPreset = (newPreset) => {
    if (!newPreset || newPreset === "") {
      newPreset = null;
      return result;
    }

    if (!presets[newPreset]) {
      warn("event tracking - invalid preset", {
        requested: newPreset,
        available: Object.keys(presets),
      });
      return result;
    }
    presetKey = newPreset;
    return result;
  };

  let setPage = (page) => {
    setTrackingPreset(page.trackingPreset);
    setCategory(page.trackingCategory);
    setPageName(page.trackingName);

    return result;
  };

  let presets = {
    tradeAwesome: {
      "formPopup1|trigger|click": {
        action: "contact_us",
        label: "impression",
      },
      "formPopup1|form|submitSuccess": {
        action: "contact_us",
        label: "form_success",
      },
      "formPopup1|phoneLink|click": {
        action: "contact_us",
        label: "click_2_call",
      },
      "video1|coverImage|click": {
        action: "watch_video",
        label: (action, options) => {
          return options.value;
        },
      },
      "video1|video|click": {
        action: "watch_video",
        label: (action, options) => {
          return options.value;
        },
      },
      "app1|downloadImage|click": {
        action: "download_app",
        label: "appstore",
      },
      "app1|downloadImage2|click": {
        action: "download_app",
        label: "google play",
      },
    },
    managerTech: {
      /*

       "header1|link1|click": {
       //action : "clicks_on_CTA_TEXT",
       extended example
       action: (action, options) => {
       return `clicks_on_${options.value}`;
       },


       category: (category, options, action) => {
       console.log("override category");
       return category;
       },
       label: (label, options, action) => {
       console.log("override aclabeltion");
       return label;
       },
       value: (value, options, action) => {
       console.log("override value");
       return value;
       },


       },
       */
      "header1|menuLink|click": {
        action: (action, options, c, data) => {
          return `clicks_on_menu_${options.value}`;
        },
      },

      // call to actions - header button2 , header link1, header link 2 | clicks_on_{{cta text}}	{{page name}}

      "header1|button2|click": {
        action: (action, options, c, data) => {
          return `clicks_on_${options.value}`;
        },
      },
      "header1|link|click": {
        action: (action, options, c, data) => {
          return `clicks_on_${options.value}`;
        },
      },
      "header1|link1|click": {
        action: (action, options, c, data) => {
          return `clicks_on_${options.value}`;
        },
      },
      "header1|link2|click": {
        action: (action, options, c, data) => {
          return `clicks_on_${options.value}`;
        },
      },

      // joins header button 1, hero1 cta, button in animation section |enter_proccess	{{page name}}
      "header1|button1|click": {
        action: "enter_proccess",
      },
      "hero1|button1|click": {
        action: "enter_proccess",
      },
      "portfolio1|button|click": {
        action: "enter_proccess",
      },
      // continues hero1link |resume_proccess	{{page name}}
      "hero1|ctaLink|click": {
        action: "resume_proccess",
      },

      "formPopup1|trigger|click": {
        action: "contact_us",
        label: "impression",
      },
      "formPopup1|form|submitSuccess": {
        action: "contact_us",
        label: "form_success",
      },
      "formPopup1|phoneLink|click": {
        action: "contact_us",
        label: "click_2_call",
      },
      "qna1|questionTitle|click": {
        action: (action, options, c, data) => {
          return `faq`;
        },
        label: (action, options, c, data) => {
          return options.value;
        },
      },
    },
    leads: {
      "leads1|form|submitSuccess": {
        action: (action, options) => {
          return `General-landing-pages-send-form-meeting`;
        },
      },
    },
    pension: {
      "formPopup1|trigger|click": {
        action: "contact_us",
        label: "impression",
      },
      "formPopup1|form|submitSuccess": {
        action: "contact_us",
        label: "form_success",
      },
      "formPopup1|phoneLink|click": {
        action: "contact_us",
        label: "click_2_call",
      },
      "videoAdvantages1|video|click": {
        action: (action, options, c, data) => {
          return `watch_video`;
        },
        label: (action, options, c, data) => {
          return options.value;
        },
      },
      "header1|menuLink|click": {
        action: (action, options, c, data) => {
          return `clicks_on_menu_${options.value}`;
        },
      },
      "qna1|questionTitle|click": {
        action: (action, options, c, data) => {
          return `faq`;
        },
        label: (action, options, c, data) => {
          return options.value;
        },
      },
      "header1|linkInDropdown|click": {
        action: "occupation",
        label(action, options) {
          return options.value;
        },
      },
      "hero1|button1|click": {
        action: "enter_process",
      },
      "videoAdvantages1|button|click": {
        action: "enter_process",
      },
      "hero1|ctaLink|click": {
        action: (action, options, c, data) => {
          return `clicks_on_${options.value}`;
        },
      },
    },
    weHishtalmut: {
      "header1|button1|click": {
        action: (action, options, c, data) => {
          return `clicks_on_${options.value}`;
        },
        label: "menu",
      },
      "header1|button2|click": {
        action: (action, options) => {
          return `clicks_on_${options.value}`;
        },
        label: "menu",
      },
      "header1|menuLink|click": {
        action: (action, options, c, data) => {
          return `clicks_on_menu_${options.value}`;
        },
      },
      "hero1|ctaLink|click": {
        action: (action, options, c, data) => {
          return `already_started_the_process`;
        },
      },
      "videoAdvantages1|video|click": {
        action: (action, options, c, data) => {
          return `watch_video`;
        },
        label: (action, options, c, data) => {
          return options.value;
        },
      },
      "qna1|questionTitle|click": {
        action: (action, options, c, data) => {
          return `faq`;
        },
        label: (action, options, c, data) => {
          return options.value;
        },
      },
    },
    currencyFund: {
      "header1|menuLink|click": {
        action: (action, options, c, data) => {
          return `clicks_on_menu_${options.value}`;
        },
      },
      "formPopup1|trigger|click": {
        action: "contact_us",
      },
      // clicks on "הצטרפו עכשיו"(two buttons)
      "header1|button1|click": {
        action: (action, options) => {
          return `clicks_on_${options.value}`;
        },
        label: "main_nav",
      },
      "hero1|button1|click": {
        action: (action, options) => {
          return `clicks_on_${options.value}`;
        },
        label: "hero_section",
      },
      "slimCta1|button|click": {
        action: (action, options) => {
          return `clicks_on_${options.value}`;
        },
        label: "cta_section",
      },
      "slimCta1|iconLink|click": {
        action: "back_to_proccess",
      },
    },
    pensionIndex: {},
    investment: {},
  };
  let mutateTrackingDataByCurrentPreset = (data, action, options) => {
    try {
      let preset = presets[presetKey];
      let eventConf = preset[action];

      if (!eventConf) {
        return data;
      }

      let overridables = ["action", "category", "label", "value"];
      overridables.forEach((key) => {
        if (typeof eventConf[key] === "undefined") {
          return true;
        }

        let mutator = eventConf[key];
        let ucFirstKey = utilities.ucFirst(key);
        if (typeof mutator === "function") {
          data.eventInfo[ucFirstKey] = mutator(
            data.eventInfo[ucFirstKey],
            options,
            action,
            data
          );
        } else {
          data.eventInfo[ucFirstKey] = mutator;
        }
      });
      return data;
    } catch (e) {
      return data;
    }

  };
  let track = async (action, options = {}) => {
    try {
      let label = options?.label ?? pageName;
      let value = options?.value ?? undefined;
      let data = {
        event: customEventName,
        eventInfo: {
          Category: category,
          Action: action,
          Label: label,
          Value: value,
        },
      };

      if (presetKey) {
        data = mutateTrackingDataByCurrentPreset(data, action, options);
      }
      if (debug) {
        info("pusing into datalayer", data);
      }

      if (!window.dataLayer) {
        warn("event tracking - no dataLayer on window!");
        return result;
      }

      window.dataLayer.push(data);
      return result;
    } catch (e) {
      console.log(e);
    }

  };

  result.setCategory = setCategory;
  result.setPageName = setPageName;
  result.setPage = setPage;
  result.track = track;
  result.trackEvent = track;
  result.setCategory = setCategory;
  return result;
};
