module.exports = {
  name: "דפי הצטרפות - מיטב",
  short_name: "דפי הצטרפות - מיטב",
  description: "הצטרפות לשירותי מיטב השונים בקלות ובמהירות",
  start_url: "/",
  display: "standalone",
  background_color: "#ffffff",
  theme_color: "#1c7d64",
  themeColor: "#1c7d64",
  msTileColor: "#1c7d64",
  appleMobileWebAppCapable: "yes",
  appleMobileWebAppStatusBarStyle: "#1c7d64",
  iconPaths: {
    favicon96: "meitavlp/images/favicons/favicon-96x96.png",
    msTitleImage: "meitavlp/images/favicons/ms-icon-144x144.png",
    favicon32: "meitavlp/images/favicons/favicon-32x32.png",
    favicon16: "meitavlp/images/favicons/favicon-16x16.png",
    appleTouchIcon: "meitavlp/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "meitavlp/images/favicons/safari-pinned-tab.svg",
    msTileImage: "meitavlp/images/favicons/msapplication-icon-144x144.png",
  },
  icons: [
    {
      src: "meitavlp/images/favicons/favicon-96x96.png",
      sizes: "96x96",
      type: "image/png",
    },
    {
      src: "meitavlp/images/favicons/ms-icon-144x144.png",
      sizes: "144x144",
      type: "image/png",
    },
    {
      src: "meitavlp/images/favicons/favicon-16x16.png",
      sizes: "16x16",
      type: "image/png",
    },
    {
      src: "meitavlp/images/favicons/apple-touch-icon-152x152.png",
      sizes: "152x152",
      type: "image/png",
    },
    {
      src: "meitavlp/images/favicons/msapplication-icon-144x144.png",
      sizes: "144x144",
      type: "image/png",
    },
    {
      src: "meitavlp/images/favicons/apple-icon-180x180.png",
      sizes: "180x180",
      type: "image/png",
    },
    {
      src: "meitavlp/images/favicons/ms-icon-310x310.png",
      sizes: "310x310",
      type: "image/png",
    },
  ],
};
